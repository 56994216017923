.MuiGrid-root{
    align-items: center;
}

.MuiGrid-item{
    padding: 4px;
}

.item-right{
    text-align: right;
}

.item-left{
    text-align: left;
    padding-left: 30px;
}

.align-vertical{
    top: 50%;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}