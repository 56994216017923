body{
  background-color: whitesmoke;
}

.App {
  text-align: center;
}

.Box{
  background-color: grey;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiToolbar-root{
  min-height: 40px !important;
}

.MuiButton-root{
  padding-top : 5px !important;
  padding-bottom : 5px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* 
.css-hboir5 {
  display: flex;
  width: 100%;
}

.css-5q4tnq{
background-color: rgb(255, 255, 255);
box-shadow: none;
display: flex;
flex-direction: column;
box-sizing: border-box;
flex-shrink: 0;
position: fixed;
top: 0px;
left: auto;
right: 0px;
color: inherit;
z-index: 1201;
transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
width: calc(100% - 60px);
border-bottom: 1px solid rgb(240, 240, 240);
}


.css-wf18hp {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-height: 60px;
}

.css-lgue59 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 0px -12px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  padding: 8px;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  font-size: 1rem;
  position: relative;
  color: rgb(38, 38, 38);
  background-color: rgb(245, 245, 245);
} */